/*****************************
 listing
*****************************/
.listing-item {
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius-md;

  .listing-image {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(.25, .46, .45, .94);
      min-height: 100%;
      object-fit: cover;
    }

    .listing-favourite {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 9;

      i {
        height: 32px;
        width: 32px;
        background-color: $white;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
        font-size: 18px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        color: $body-color;

        &:hover {
          color: $red;
        }

      }

    }
    .listing-favourite.listing-like{
       i {
          color: $red;
        &:hover {
          color: $red;
        }
      }
    }

  }

  .listing-details {
    padding: 25px;

    .listing-title {
      .title {
        font-size: 22px;
        line-height: 32px;
        font-weight: bold;
        color: $gray-2;

        &:hover {
          color: $primary;
        }

      }

      .country-flags {
        flex: 0 0 40px;
        text-align: right;
        align-self: center;
      }

    }

    .listing-rating {
      padding: 12px 0;
      border-bottom: 1px solid $border-color;
      border-top: 1px solid $border-color;
      align-items: center;

      .rating {
        i {
          font-size: 14px;
        }

      }

      .price {
        font-size: 22px;
        font-weight: bold;
        color: $gray-2;

        span {
          color: $body-color;
          font-size: 16px;
          font-weight: 400;
          margin-right: 10px;
        }

      }

    }

    .listing-loaction {
      color: $body-color;
      font-size: 12px;
      padding: 10px 0 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%;

      i {
        font-size: 18px;
        margin-right: 6px;
      }

      img {
        font-size: 18px;
      }

      &:hover {
        color: $primary;
      }

    }

    .listing-info {
      display: flex;
      padding: 20px 0;

      img {
        flex: 0 0 41px;
        width: 41px;
        height: 41px;
        margin-right: 10px;
        border-radius: 50%;
      }

      p {
        font-size: 14px;
        line-height: 24px;
      }

    }

  }
  &:hover{
    .listing-image{
      img{
        transform: scale(1.1);
      }
    }
  }
}

.listing-item.listing-item-2 {
  display: flex;
  box-shadow: inherit;
  position: relative;
  padding: 50px 0;
  width: 99%;
  background-color: inherit;
  margin: 0 5px;

  .listing-image {
    width: 60%;
    display: flex;
    position: relative;
    border-radius: $border-radius;
  }

  .listing-details {
    padding: 40px;
    align-self: center;
    position: relative;
    width: 45%;
    .price {
      font-size: 22px;
      font-weight: bold;
      color: $gray-2;
      padding-top: 15px;

      span {
        color: $body-color;
        font-size: 16px;
        font-weight: 400;
        margin-right: 10px;
      }

    }

    .listing-title {
      .title {
        font-size: 26px;
      }

    }

    .listing-loaction {
      padding: 20px 0 30px;
    }

    .listing-rating {
      padding: 15px 0;
    }

    .listing-info {
      padding: 20px 0 30px;
    }

  }

  &:before {
    width: 80%;
    height: 100%;
    content: "";
    background-color: $white;
    box-shadow: $box-shadow-sm;
    position: absolute;
    right: 0;
    top: 0;
  }

}

.listing-item.listing-item-3 {
  display: flex;
  align-items: center;
  padding: 10px;

  .listing-image {
    flex: 0 0 120px;
    height: 120px;
    width: 120px;
    border-radius: 6px;

    img {
      border-radius: 6px;
    }

  }

  .listing-details {
    padding: 10px 0px 10px 15px;
    width: 60%;
    flex: auto;

    .listing-rating {
      padding: 10px 0 0;
      border-bottom: inherit;

      .price {
        font-size: 14px;

        span {
          font-size: 14px;
        }

      }

      .rating {
        span {
          font-size: 14px;
        }

      }

    }

    .listing-title {
      .title {
        font-size: 18px;
      }

    }

  }

  .listing-loaction {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 0;
    display: inline-block;
    max-width: 100%;
  }

}

.owl-carousel {
  .listing-item {
    margin-bottom: 20px;
  }

}

.masonry {
  column-count: 3;
  column-gap: 0;
}

.masonry .masonry-item {
  vertical-align: top;
}

/*listing-item-details*/
.listing-item.listing-item-details {
  box-shadow: none;

  .listing-details {
    margin-bottom: 24px;
    padding: 0px;

    .listing-title {
      .title {
        font-size: 26px;
        line-height: 36px;
      }

    }

    .listing-favourite {
      .btn.btn-light {
        box-shadow: $box-shadow-sm;
      }

    }

  }

  .listing-detail-page {
    .listing-detail-box {
      box-shadow: $box-shadow;
      border-radius: $border-radius-md;
      padding: 30px;
      margin-bottom: 30px;

      .detail-title {
        border-bottom: 1px solid $border-color;
        padding-bottom: 12px;
        margin-bottom: 20px;
      }

      .rating {
        font-size: 14px;

        span {
          border: 1px solid #0abb0a;
          padding: 0 4px;
          line-height: 16px;
          border-radius: 3px;
        }

      }

      .accordion {
        .accordion-item {
          position: relative;
          border: none;
          padding-bottom: 30px;
          margin-bottom: 0px;

          &:last-child {
            padding-bottom: 0px;
          }

          &:before {
            position: absolute;
            content: "";
            left: 14px;
            height: 100%;
            width: 1px;
            border: 1px dashed $border-color;
          }

          .accordion-button {
            padding: 0;
            box-shadow: none;

            &:before {
              font-size: 16px;
              font-family: "Font Awesome 5 Free";
              display: inline-block;
              content: "\f192";
              font-weight: 900;
              color: $body-color;
              margin-right: 10px;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border-radius: 100%;
            }

            &:after {
              display: none;
            }

            &:focus {
              box-shadow: none;
            }

          }

          .accordion-button:not(.collapsed) {
            background-color: inherit;
            box-shadow: none;
            color: $primary;

            &:before {
              content: "\f3c5";
              background-color: $gray-2;
              color: $primary;
            }

          }

          .accordion-body {
            padding: 16px 20px 16px 40px;
          }

          &:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          &:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            &:before {
              display: none;
            }

          }

        }

      }
      &:hover {
        .listing-image {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    .listing-detail-box.reviews-box{
      .avatar{
        width: 70px;
        flex: 0 0 70px;
        img{
          border-radius: 100%;
          border: 4px solid $white;
         box-shadow: 0px 1px 10px 0px rgba(0, 0, 0,0.1);
        }

      }
    }

  }

  .sidebar {
    .widget {
      padding: 0px;
      .widget-title {
        padding: 15px 20px 0;
        margin-bottom: 0px;

        h6{
          color: $gray-2 !important;
        }

        .collapse-title {
          font-size: 24px;
          font-weight: 600;
          color: $gray-2;
          font-family: "Playfair Display", serif;
          display: flex;
          align-items: center;

          i {
            margin-left: auto;
          }

        }

      }

      .widget-content {
        padding: 25px 20px;

      }

    }

  }

.sidebar.tour-sidebar{
  .widget{
    .widget-title{
      border-radius: $border-radius;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .collapse-title.collapsed{
      &:before{

      }
    }
  }
}

}

.ratings {
  border: none;
  float: left;
}

.ratings > input {
  display: none;
}

.ratings > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  content: "\f005";
  font-weight: 900;
}

.ratings > .half:before {
  content: "\f089";
  position: absolute;
}

.ratings > label {
  color: #ddd;
  float: right;
}

.ratings > input:checked ~ label, .ratings:not(:checked) > label:hover, .ratings:not(:checked) > label:hover ~ label {
  color: #FFD700;
}

.ratings > input:checked + label:hover, .ratings > input:checked ~ label:hover, .ratings > label:hover ~ input:checked ~ label, .ratings > input:checked ~ label:hover ~ label {
  color: #FFED85;
}

/*****************************
    Responsive
*****************************/
@media (max-width:1200px) {
  .sidebar {
    .widget {
      .listing-item.listing-item-3 {
        .listing-image {
          flex: 0 0 90px;
          width: 90px;
          height: 90px;
        }

        .listing-details {
          .listing-rating {
            .price {
              font-size: 12px;

              span {
                font-size: 12px;
              }

            }

            .rating {
              span {
                font-size: 12px;
              }

            }

          }

        }

      }

    }

  }

}

@media (max-width:991px) {
  .listing-item.listing-item-2 {
    .listing-image {
      width: 50%;
    }

    .listing-details {
      width: 50%;
      padding: 30px;
    }

  }

}

@media (max-width:767px) {
  .listing-item.listing-item-2 {
    display: inherit;
    padding: 0;

    &:before {
      width: 100%;
    }

    .listing-image {
      width: 100%;
    }

    .listing-details {
      width: 100%;
      padding: 25px;
    }

  }

}
