/*****************************
  video
*****************************/
.video-box {
    img {
        width: 70px;
        height: 70px;
    }

    .video-icon a {
        i {
            width: 30px;
            height: 30px;
            background: $white;
            align-items: center;
            justify-content: center;
            margin-left: -10px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            color: $black;
            font-size: 12px;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            i {
                background: $primary;
                color: $white;
            }

            h6 {
                color: $primary;
            }

        }

    }

    h6 {
        font-size: 18px;
        padding-left: 15px;
        transition: all 0.3s ease-in-out;
    }

}
