/*****************************
 Accordion
*****************************/

.accordion {

  .accordion-item{
    border: none;
    margin-bottom: 20px;
    .accordion-button{
      padding:18px 30px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      background-color: $white;
      border-radius: $border-radius;
      box-shadow: $box-shadow-sm;
      &:after{
        display:none;
      }
      i{
        position: absolute;
        right: 30px;
        transition: all 0.3s ease-in-out;
      }
    }

    .accordion-button:not(.collapsed){
      box-shadow: none;
      background: $primary;
      color: $white;
      &:after{
        display:none;
      }
      i{
        transform: rotate(-180deg);
      }
    }
  }

    .card-header {
    background: none;
    border-bottom: none;
    padding: 0;
    border-radius: inherit;

    .accordion-title {
      display: flex;
      align-items: center;
      .btn{
        background: $primary;
        padding: 18px 30px;
      }

      i {
        font-size: 12px;
        margin-left: auto;
      }

    }

  }

}



.accordion-checkbox {
  .card {
    label {
      display: flex;

      .accordion-title {
        padding-left: 11px;
        display: inline-block;
      }

    }

    .card-body {
      padding: 10px 0px 0px 36px;
    }

    .pay-icon {
      height: 20px;
      margin-left: auto;
    }

  }

}



.asked-questions{
  .questions-title{
    font-weight: 600;
    display: flex;
    font-size: 20px;
    line-height: 30px;

    .number{
       width: 32px;
       height: 32px;
       border-radius: $border-radius-lg;
       background-color: $primary;
       display:flex;
       flex: 0 0 32px;
       justify-content: center;
       align-items: center;
       font-size: 16px;
       margin-right: 20px;
       color: $white;
    }
  }
}

.booking-02 {
  padding:40px 28px;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  .accordion {
    .custom-control {
      .custom-control-label {
        .accordion-title {
          font-weight: 700;
        }
      }
    }
  }
}



@media (max-width: 767px) {
  .accordion {
    .accordion-item{
      .accordion-button{
        padding:18px 30px 18px 20px;
        i{
          right: 20px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .booking-02 {
     padding: 30px 15px;
    .accordion-checkbox {
        .card {
           label {
            display: block !important;
            .pay-icon {
              margin-left: 0 !important;
              display: block;
            }
           }
        }
    }
  }



}
