/*****************************
  Portfolio
*****************************/
.filters-group {
  display: table;
  margin: 0 auto 30px;
  text-align: center;

  button {
  background: $primary;
  border: none;
  z-index: 1;
  position: relative;
  font-size: 16px;
  padding: 15px 40px;
  color: $white;
  border-radius: $border-radius-sm;
  transition: all 0.3s ease-in-out;
  margin: 0 5px;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: #40e3e7;
    transition: all 0.4s ease;
    border-radius: $border-radius-sm;
  }
  &:hover {
    color: $white;
    box-shadow: none;
    outline: none;
    &:after {
      left: 0;
      width: 100%;
    }
  }
  &:focus {
    box-shadow: none;
    outline: none;
    color: $white;
  }

  &:active {
    box-shadow: none;
    outline: none;
    color: $white;
  }
}

  button.active {
    background-color: $primary;
    color: $white;
  }

}

.my-shuffle-container {
  margin-left: -15px;
  margin-right: -15px;

  .grid-item {
    width: 33.33%;
    padding: 15px;
  }

}

.my-shuffle-container {
  .portfolio {
    margin-bottom: 0;
  }

}

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.columns-1 {
  .grid-item {
    width: 100%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-2 {
  .grid-item {
    width: 50%;
    padding: 15px;
    float: left;
  }

}

.my-shuffle-container.columns-3 {
  .grid-item {
    width: 33.33333333%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-4 {
  .grid-item {
    width: 25%;
    padding: 10px;
  }

}

.my-shuffle-container.columns-5 {
  .grid-item {
    width: 20%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-6 {
  .grid-item {
    width: 16.666666%;
    padding: 0px;
  }

}

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
  width: 100%;
}

/* Responsive */
@media (max-width:991px) {
  .filters-group button {
    margin: 0 5px 10px;
  }

  .filters-group button:first-child {
    margin-left: 0;
  }

  .filters-group button:last-child {
    margin-right: 0;
  }

}

@media (max-width:1199px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-5 .grid-item {
    width: 25%;
  }

}

@media (max-width:991px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 33.333%;
  }

}

@media (max-width:767px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 50%;
  }

}

@media (max-width:575px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 100%;
  }

  .my-shuffle-container {
    margin: 0;
  }

}

// portfolio
.portfolio {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .portfolio-title {
    display: block;
    color: $gray-1;
    font-size: 18px;
    font-weight: 500;

    &:hover {
      color: $primary;
    }

  }

  .portfolio-services {
    display: block;
    margin-bottom: 10px;

    &:hover {
      color: $gray-1;
    }

  }

  .portfolio-info {
    padding: 20px;
    width: calc(100% - 30px);
    position: absolute;
    left: 50%;
    bottom: 15px;
    z-index: 2;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, 0%);
    background: $white;

    p {
      max-height: 66px;
      overflow: auto;
    }

  }

  &:hover {
    .portfolio-info {
      opacity: 1;
      bottom: 20px;
    }

  }

  .portfolio-lg {
    + {
      .portfolio-info {
        p {
          max-height: 340px;
          overflow: auto;
        }

      }

    }

  }

}



/* style 01 */
.portfolio-style-01 {
  position: relative;
  border-radius: $border-radius;

  &:before {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    top: 0;
    content: "";
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    &:before {
      height: auto;
      transition: all 0.4s ease-in-out;
    }

  }

  .portfolio-info {
    background: $white;
    border-radius: $border-radius;
    opacity: 0;

    p {
      color: $white;
    }

    .portfolio-services {
      color: $white;

      &:hover {
        color: $white;
      }

    }

    .portfolio-title {
      color: $white;

      &:hover {
        color: $secondary !important;
      }

    }

  }

  .icon-btn {
    opacity: 1;
    position: absolute;
    top: 10px;
    z-index: 99;
    right: 5px;
  }

}

/*portfolio-style-02*/

.portfolio-style-02{
  .portfolio-info{
    border-radius: $border-radius;
    &:hover{
      .portfolio-title {
        &:hover {
          color: $secondary !important;
        }
      }
    }
  }
}

/*portfolio-style-03*/

.portfolio-style-03 {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius;
  .portfolio-images {
    position: relative;
    border-radius: $border-radius;
  }
  .portfolio-img{
    border-radius: $border-radius;
    overflow: hidden;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .portfolio-info-02 {
    background: transparent;

    .popup-icon {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: $border-radius-lg;
      position: absolute;
      margin: 0 auto;
      top: 40%;
      opacity: 0;
      visibility: hidden;
      right: 0;
      left: 0;
      text-align: center;
      transition: all 0.3s ease-in-out;
      background: $white;
      transform: translateY(-50%);
    }

  }

  &:hover {
    img{
      transform: scale(1.1);
    }

    .portfolio-info-02 {
      .popup-icon {
        opacity: 1;
        top: 50%;
        visibility: visible;
      }

    }

  }

    img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

}


/*Portfolio Style Morden*/
.portfolio-style-morden {
  .portfolio {
    overflow: visible;
    .portfolio-img {
      position: relative;
      border-radius: $border-radius;
      overflow: hidden;
      transition: transform 0.28s ease;
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 1;
        content: "";
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(15, 15, 15, 0.75) 100%);
        transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        cursor: pointer;
      }
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 8;
        content: "";
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), $primary 100%);
        transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        cursor: pointer;
        opacity: 0;
      }
    }
    &:hover {
      .portfolio-img {
        transform: scale(1.05) translateZ(0);
        &:after {
          opacity: 1;
        }
      }
    }
    .portfolio-info {
      background: none !important;
    }
    .portfolio-title {
      font-size: 24px;
      font-weight: 600;
      color: $white !important;
      margin-bottom: 5px;
    }
    .portfolio-services {
      color: $white !important;
      opacity: 0.7;
    }
  }
}

/*Portfolio Style Fancy*/

.portfolio-style-fancy {
  .portfolio {
    .portfolio-info {
      position: absolute;
      display: block;
      width: 100%;
      height: auto;
      bottom: 0;
      left: 0;
      padding: 22px 25px 17px;
      background-color: $white;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      -webkit-transition: -webkit-transform .4s ease-in-out;
      transition: -webkit-transform .4s ease-in-out;
      transition: transform .4s ease-in-out;
      .portfolio-info-wrapper {
        opacity: 0;
        -webkit-transform: translateY(-150%);
        -ms-transform: translateY(-150%);
        transform: translateY(-150%);
        -webkit-transition: opacity .4s cubic-bezier(.160,.85,.45,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);
        transition: opacity .4s cubic-bezier(.160,.85,.45,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);
        transition: transform .4s cubic-bezier(.77,0,.175,1),opacity .4s cubic-bezier(.160,.85,.45,1);
      }
      .portfolio-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .portfolio-services {
        color: $body-color !important;
      }
    }
    &:hover {
      .portfolio-info {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: -webkit-transform .50s cubic-bezier(.77,0,.175,1);
        transition: -webkit-transform .50s cubic-bezier(.77,0,.175,1);
        transition: transform .50s cubic-bezier(.77,0,.175,1);
        .portfolio-info-wrapper {
          opacity: 1;
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
          -webkit-transition: .50s cubic-bezier(.160,.85,.45,1) .2s;
          transition: .50s cubic-bezier(.160,.85,.45,1) .2s;
        }
      }
    }
  }
}

/*Portfolio Style Agency*/
.portfolio-style-agency{

}

