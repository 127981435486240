/*****************************
  Pricing
*****************************/

.pricing-item{
  background-color: $white;
  box-shadow: $box-shadow-sm;
  padding: 45px;
  border-radius: $border-radius-md;
  text-align: center;
  .pricing-title{
    font-weight: bold;
    margin-bottom: 20px;
  }
  .pricing-price{
    margin: 20px 0 30px;
    color: $gray-2;
    span{
       font-size: 52px;
       line-height: 1;
       font-weight: bold;
       font-family: "Playfair Display", serif;
    }
  }
  .pricing-list{
    padding: 20px;
    background-color: $gray-1;
    margin-bottom: 25px;
    border-radius: $border-radius;
    ul{
      li{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 500;
        color: $gray-2;
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
}

.pricing-item.active{
  background-color: $primary;
  .pricing-list{
     background-color:  rgba($black, 0.05);
     ul{
      li{
        color: $white;
      }
     }
  }
}


/*****************************
    Responsive
*****************************/

@media (max-width: 767px) {
  .pricing-item{
    padding: 30px;
  }
}
