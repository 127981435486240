/*****************************
  Footer
*****************************/
.footer {
	.footer-inner-shape {
    padding: 22px;
    position: absolute;
    top: -31px;
    z-index: 9;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
   }
	.min-footer {
		padding: 80px 0 70px 0;

		.newsletter {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid $border-color;
			padding-bottom: 40px;
			margin-bottom: 40px;

			.newsletter-title{
				letter-spacing:3px;
			}
		}

		.footer-map{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.social-icon{
	  	ul{
	    	li{
	    		a{
	    			margin-right: 30px;
	    		}
	    	}
	    }
	  }

	}

	.footer-title {
		margin-bottom: 40px;
	}

	.footer-logo {
		width: 150px;
	}

	.footer-contact-info {
		ul {
			width: 100%;

			li {
				display: flex;
				margin-bottom: 30px;
				align-items: center;

				i {
					font-size: 18px;
					min-width: 25px;
					margin-right: 15px;
				}

				img {
					height: 36px;
				}

				span {
					color: $gray-2;
					margin-left: 20px;
				}
				span.phone{
					font-size: 16px;
					color: $body-color;
				}
				span.address{
					color: $body-color;
				}
				span.email{
					color: $body-color;
				}

			}

		}

	}

	.footer-link {
		ul {
			li {
				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: $gray-2;

					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

	.footer-subscribe {
		position: relative;
		width: 50%;

		form {
			.form-control {
				background: rgba($white, 1);
				border: 1px solid $border-color;
				color: $gray-2;
				border-radius: $border-radius;
				margin-bottom: 0px;
				padding-right: 160px;
				height: 50px;
			}

			.form-control::-moz-placeholder {
				color: $body-color;
			}

			.form-control::-ms-input-placeholder {
				color: $body-color;
			}

			.form-control::-webkit-input-placeholder {
				color: $body-color;
			}


			.btn {
				height: 50px;
				line-height: 20px;
				font-size: 14px;
				padding: 15px 30px;
				position: absolute;
				top: 0;
				right: 0;
				background: $primary;
				color: $white;
				border-top-left-radius:0;
				border-bottom-left-radius:0;
				&:after{
					border-top-left-radius:0;
					border-bottom-left-radius:0;
				}
			}
		}

	}

	.footer-bottom {
		padding: 25px 0;

		.copyright {
			p {
				font-size: 15px;
				margin-bottom: 0;

				a:hover {
					color: $gray-2;
				}

			}
			.copyright-menu{
         ul{
         	 padding: 0;
         	 li{
         	 	a{
         	 		color: $body-color;
         	 		padding: 0px 0px 0px 30px;
         	 		&:hover{
         	 			color: $primary;
         	 		}
         	 	}
         	 	&:first-child{
         	 		a{
         	 			padding-left: 0;
         	 		}
         	 	}
         	 }
         }
			}

		}

	}

}

.footer{
  .footer-menu{
    ul{
    	display: flex;
    	justify-content: center;
    	list-style: none;
    	flex-wrap: wrap;
    	li{
    		display: flex;
    		a{
    			color: $white;
    			padding: 10px 35px 10px 0;
    			font-size: 16px;
    			&:hover{
    				color:$primary;
    			}
    		}
    		&:last-child{
  				padding-right:0px;
  			}
    	}
    }
  }
}

.footer{
  .footer-menu.dark-text{
  ul{
  	display: inline-block;
  	padding: 0;
  	margin-bottom: 0;
  	li{
  		a{
        color: $body-color;
        padding:0 0 15px;
        &:hover{
        	color: $primary;
        }
  		}
  		&:last-child{
  			a{
  				padding-bottom: 0;
  			}
  		}
  	}
   }
  }
}

.footer.footer-style-02{
	.footer-logo{
		justify-content: center;
		margin: 0 auto;
	}
	.footer-menu{
    ul{
    	padding: 0;
    	margin: 0;
    	li{
    		a{
    			padding: 12px 18px;
    		}
    	}
    }
  }
  .social-icon.social-rounded{
  	 ul{
    	padding: 0;
    	li{
    		a{
    			margin: 0px 15px;
    		}
    	}
    }
  }
  .footer-bottom{
  	.copyright{
  		p.text-white{
  			a{
  				color:$white;
		  		&:hover{
		        color: $primary;
		      }
		  	}
  		}
  	}

  }
}

.footer.footer-style-03{
	.min-footer{
    padding: 115px 0 50px 0;
    .social-icon.space-right{
    	ul{
    		justify-content:end;
    		display: flex;
    		li{
    			a{
    				margin-right: 60px;
    			}
    			&:last-child{
    				a{
	    				margin-right: 0px;
	    			}
    			}
    		}
    	}
    }
    .b-bottom{
    	border-bottom: 1px solid  rgba($primary, 0.10);
    	margin: 40px 0px 50px;
    }

    form{
    	.form-control{
    		margin-bottom: 20px;
    	}
    	.btn {
    		padding: 13px 40px;
    	}
    }
	}
}



@media (max-width:991px) {
  .footer  {
  	.min-footer{
  		padding: 60px 0 50px 0;
  	}
  }

  .footer.footer-style-03{
		.min-footer{
	    .social-icon.space-right{
	    	ul{
	    		li{
	    			a{
	    				margin-right: 30px;
	    			}
	    		}
	    	}
	    }
	    .b-bottom {
			  margin: 40px 0px 40px;
			}
		}
	}
}



@media (max-width:767px) {
	.footer {
		.footer-title {
			margin-bottom: 20px;
		}

		.footer-subscribe {
			width: 70%;
		}

		.footer-contact-info {
			ul{
				li{
					margin-bottom:15px;
				}
			}
		}

		 .min-footer {
		    padding: 50px 0 50px 0;
		    .newsletter {
					padding-bottom: 30px;
					margin-bottom: 30px;
				}

		    .footer-map{
					position: inherit;
					top: inherit;
					left:inherit;
					transform: inherit;
				}
		  }
	}

	.footer.footer-style-03{
		.min-footer{
			padding: 115px 0 25px 0;
			.social-icon.space-right{
				ul{
					justify-content: start;
				}
			}
			.b-bottom {
			  margin: 30px 0px 30px;
			}
		}
	}



}

@media (max-width:575px) {
	.footer {
		.newsletter {
			flex-direction: column;
		}

		.footer-subscribe {
			width: 100%;
			margin-top: 15px;
       form {
       	.form-control{
          padding-right:175px;
       	}
      }
		}
     .min-footer {
		    padding: 40px 0 40px 0;
		 }
		 .footer-menu {
		 	ul{
		 		 li{
             a{
               padding: 6px 25px 6px 0;
             }
		 		  }
		  	}
		 }
	}

	.footer.footer-style-02{
	  .social-icon.social-rounded{
	  	ul{
	    	li{
	    		a{
	    			margin: 0px 7px;
	    		}
	    	}
	    }
	  }
	}


}
