/*****************************
  Team
*****************************/

.team {
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    .team-image {
        position: relative;
        overflow: hidden;
        border-radius: $border-radius-sm;

        .team-social {
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 85%;
            display: block;
            z-index: 1;
            content: "";
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(15, 15, 15, 0.75)));
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(15, 15, 15, 0.75) 100%);
            -webkit-transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
            transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
            cursor: pointer;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            ul {
                position: relative;
                bottom: 30px;
                opacity: 0;
                transition: all 0.3s ease-in-out;
                li {
                    a {
                        margin: 0px 5px;
                        background: $white;
                        color: $black;
                        padding: 10px;
                        height: 40px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: $border-radius-sm;
                        position: relative;
                        z-index: 1;

                        &:before{
                            content: '';
                            background-color: $primary !important;
                            width: 100%;
                            height: 100%;
                            border-radius: $border-radius-md;
                            transition: all 490ms ease;
                            transform: scale(0,0);
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            z-index: -1;
                        }

                        &:hover {
                            background: $primary;
                            color: $white;
                                &:before{
                                    transform: scale(1,1);
                                }
                        }

                    }

                }

            }

        }



        img {
            transform: scale(1);
            transition: transform 7s;
        }

    }

    .team-detail {
        padding-top: 20px;
        padding-bottom: 30px;

        .team-label {
            padding-bottom: 10px;
            display: block;
            font-size: 12px;
            font-weight: 500;
        }

        .team-title {
            font-size: 18px;
            font-weight: 600;
            color: $gray-2;
        }

    }

    &:hover {
        .team-social {
            opacity: 1;
            ul {
                bottom: 15px;
                opacity: 1;
            }
        }

    }

}

.team-sticky{
    .team{
      .team-img  {
        img{
            border-radius: 6px 6px 0px 0px;
        }
      }
      .team-info {
        padding: 35px;

      }
    }
}

.team:hover .team-image img {
    transform: scale(1.1);
}

.team-sticky.is-sticky {
    top: 100px;
}

.team {
    .team-info.clients-detail {
        ul{
            li{
                color: $white;
            }
        }
        .social-icon {
            ul {
                li {
                    a {
                        font-size: 16px;
                        color: $white;
                        margin-right: 15px;

                        &:hover {
                            color: $secondary;
                        }

                    }

                }

            }

        }

    }

}

